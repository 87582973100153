// Polyfills for rails webpacker
import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start();
require("turbolinks").start();
// require("@rails/activestorage").start();
// require("channels");


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../devise/images', true)
const imagePath = (name) => images(name, true)

import "controllers" // stimulus

import '../devise/devise' // css

if (!window.Stampay) {
  window.Stampay = {};
}

// Enable again when using content_security_policy again
// document.addEventListener("turbolinks:request-start", function (event) {
//   let xhr = event.data.xhr;
//   xhr.setRequestHeader("X-Turbolinks-Nonce", document.querySelector('meta[name=csp-nonce]').content);
// });
