import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["collectionSelect"];

  initialize() {
    this.selectedCollections = []
  }

  addCardCollection(event) {
    let selectedOptions = [...event.currentTarget.selectedOptions]

    for(let selectedOption of selectedOptions) {
      if (!this.selectedCollections.includes(selectedOption.value)) {
        fetch(`${event.currentTarget.dataset.url}?id=${selectedOption.value}`, {
          headers: {
            'Accept': 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
            'X-Requested-With': 'XMLHttpRequest'
          },
          method: 'GET',
          credentials: 'include',
          cache: 'no-store'
        }).then(function (data) { // Promise
          return data.text();
        }).then(function (response) {
          let script = document.createElement('script');
          script.nonce = document.querySelector('meta[name=csp-nonce]').content;
          script.setAttribute('nonce', script.nonce);
          script.text = response;
          document.head.appendChild(script).parentNode.removeChild(script)
        });
      }
    }

    let removedElements = this.selectedCollections
      .filter(e => !selectedOptions.map(x => x.value).includes(e)) // Get all elements that are in previous selectedCollections but not in current selectedOptions
      .filter(String) // Remove empty string values

    for (let removedElement of removedElements) {
      document.getElementById(`card-select-${removedElement}`).remove()
    }

    this.selectedCollections = selectedOptions.map(x => x.value)
  }

  set selectedCollections(value) {
    this.data.set('selectedCollections', value)
  }

  get selectedCollections() {
    return this.data.get('selectedCollections').split(',')
  }
}
