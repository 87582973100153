import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["input"];

  initialize() { }

  //onclick
  start(event) {
    event.currentTarget.contentEditable = true
  }

  //onkeypress - characterCode == 13
  pressedKey(event) {
    if ( event.target.innerHTML === '' ) {
      event.target.classList.add('editable-empty');
    } else {
      event.target.classList.remove('editable-empty');
    }

    if ( event.which === 13 ) {
      this.end(event.currentTarget);
    }
  }

  // onblur
  focusLost(event) {
    this.end(event.currentTarget);
  }

  end(currentTarget) {
    if ( currentTarget.innerHTML === '' ) {
      currentTarget.classList.add('editable-empty');
    }

    currentTarget.contentEditable = false;

    let body = {};
    let obj = {};
    obj[currentTarget.dataset.name] = currentTarget.innerHTML;
    body[currentTarget.dataset.model] = obj;

    fetch(currentTarget.dataset.url, {
      body: JSON.stringify(body),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      },
      method: 'PATCH',
      credentials: 'include',
      cache: 'no-store'
    })
    //   .then(function (data) { // Promise
    //   return data.text();
    // }).then(function (response) {
    //   let script = document.createElement('script');
    //   script.nonce = document.querySelector('meta[name=csp-nonce]').content;
    //   script.text = response;
    //   document.head.appendChild(script).parentNode.removeChild(script)
    // });
  }
}
