import {Controller} from "stimulus"

/**
 * This controller fixes bugs with the ujs adapter of rails
 *
 * Currently:
 *  - Unchecking checkboxes with data-remote="true"
 */
export default class extends Controller {
  static targets = ['checkbox'];

  initialize() { }

  changeCheckboxValue(event) {
    let value = event.currentTarget.checked * event.currentTarget.checked

    event.currentTarget.dataset.params = `${event.currentTarget.name}=${value}`
  }
}
