import { Controller } from "stimulus"

/**
 * Starts jasny bootstrap like off canvas
 *
 * TODO: Animation
 */
export default class extends Controller {
  static targets = ["element"];

  toggle () {
    this.elementTarget.classList.toggle('d-block')
  }
}
