import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['cloneElement', 'insertElement', 'insertBeforeElement'];

  /**
   * Clone Action
   */
  clone(event) {
    event.preventDefault()

    let clonedElement = this.cloneElementTarget.cloneNode(true)
    clonedElement.value = ''

    if (this.insertBeforeElementTarget !== undefined) {
      this.insertElementTarget.insertBefore(clonedElement, this.insertBeforeElementTarget)
    } else {
      this.insertElementTarget.appendChild(clonedElement)
    }
  }
}
