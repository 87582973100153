import { Controller } from "stimulus"
import Inputmask from 'inputmask'

/** https://github.com/RobinHerbots/Inputmask **/
export default class extends Controller {
  static targets = ["input"];

  initialize () {
    Inputmask().mask(this.inputTargets)
  }
}
