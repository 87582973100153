import BSN from 'bootstrap.native/dist/bootstrap-native-v4'

const setFlashMessage = (message, type = 'warning') => {
  let element = (domstring) => {
    let html = new DOMParser().parseFromString(domstring, 'text/html')
    return html.body.firstChild
  }

  let flash = element(`<div class="alert alert-${type} alert-dismissable fade show fixed-top mt-5 col-8 mx-auto" role="alert" data-target="alert.alert">${message}<button type="button" class="close" aria-label="Close" data-action="click->alert#close"><span aria-hidden="true">&times;</span></button></div>`)
  let body = document.getElementsByTagName('body')[0]

  body.insertBefore(flash, body.firstChild)

  new BSN.Alert(flash)
};

export default setFlashMessage
