import {Controller} from "stimulus"

/**
 * This controller sets checkboxes to indeterminate
 *
 * Just assign this controller with target "checkbox" to any checkbox you want to have indeterminate
 */
export default class extends Controller {
  static targets = ["checkbox"];

  initialize() {
    for ( let input of this.checkboxTargets) {
      input.indeterminate = true;
    }
  }
}
