import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import { setFlashMessage } from "../utils";

export default class extends Controller {
  static targets = ["form", "startDate", "endDate"];

  initialize () {
    this.defaultAction = this.formTarget.action
  }

  submit (event) {
    // event.preventDefault();

    // this.formTarget.action = event.currentTarget.href;
    this.formTarget.action = event.currentTarget.dataset['path'];

    // Rails.fire(this.formTarget, 'submit');
    //
    // this.formTarget.action = this.defaultAction
  }

  download (event) {
    this.showDownloadNotice();

    let urlWithoutParameters = event.currentTarget.href.split('?')[0];

    event.currentTarget.href = `${urlWithoutParameters}?${Rails.serializeElement(this.formTarget)}&partner[trigger_id]=${event.currentTarget.id}`;

    this.indicateDownloadInProgress(event.currentTarget);
  }

  downloadAll (event) {
    this.showDownloadNotice();

    let urlWithoutParameters = event.currentTarget.href.split('?')[0];
    let startDate = `${this.startDateTarget.name}=${this.startDateTarget.value}`;
    let endDate = `${this.endDateTarget.name}=${this.endDateTarget.value}`;
    event.currentTarget.href = `${urlWithoutParameters}?${startDate}&${endDate}&partner[trigger_id]=${event.currentTarget.id}`

    this.indicateDownloadInProgress(event.currentTarget);
  }

  showDownloadNotice () {
    setFlashMessage('Der Download wird im Hintergrund vorbereitet. Du kannst dein Portal ganz normal weiter verwenden.', 'success')
  }

  indicateDownloadInProgress (element) {
    element.getElementsByTagName('svg')[0].classList.remove('fa-download');
    element.getElementsByTagName('svg')[0].classList.add('fa-spin', 'fa-circle-notch');
    element.classList.add('disabled')
  }
}
