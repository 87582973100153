import { Controller } from "stimulus"
import { config, dom, library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowCircleUp,
  faAt,
  faBuilding,
  faBullhorn,
  faCalendar,
  faChartBar,
  faCheck,
  faCircleNotch,
  faClock,
  faCogs,
  faComment,
  faCrosshairs,
  faCubes,
  faCut,
  faDownload,
  faGift,
  faIdCard,
  faKey,
  faMoneyBillAlt,
  faPlus,
  faPrint,
  faSearch,
  faShippingFast,
  faShoppingCart,
  faSignOutAlt,
  faStar,
  faTachometerAlt,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons'

/**
 * Fontawesome
 */
export default class extends Controller {
  initialize () {
    config.autoReplaceSvg = false;
    config.autoAddCss = false;
    library.add(faDownload,
      faCogs,
      faTachometerAlt,
      faChartBar,
      faCrosshairs,
      faStar,
      faIdCard,
      faCubes,
      faBullhorn,
      faShoppingCart,
      faComment,
      faKey,
      faSignOutAlt,
      faSearch,
      faAt,
      faBuilding,
      faTrash,
      faPlus,
      faArrowCircleLeft,
      faArrowCircleRight,
      faArrowCircleUp,
      faArrowCircleDown,
      faMoneyBillAlt,
      faCut,
      faGift,
      faCircleNotch,
      faPrint,
      faClock,
      faCalendar,
      faTimes,
      faCheck,
      faShippingFast,
    );
  }

  connect () {
    dom.i2svg();
  }
}
