import { Controller } from "stimulus"
import BSN from 'bootstrap.native/dist/bootstrap-native-v4'

/**
 * This controller starts Bootstrap Alert functionality.
 *
 * The alert needs to be assign as alert target.
 * The close button must call the action close
 */
export default class extends Controller {
  static targets = ["alert", "close"];

  connect () {
    if (this.hasAlertTarget) {
      new BSN.Alert(this.alertTarget)
    }
  }

  close () {
    this.alertTarget.Alert.close()
  }
}
