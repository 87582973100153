import { Controller } from "stimulus"
import BSN from 'bootstrap.native/dist/bootstrap-native-v4'

export default class extends Controller {
  static targets = [
    'nameModal', // Modal with the name input
    'campaignType', // Campaign type hidden input field
    'mediaBox', // Media Box for offer selection
    'daily', // Daily rule type inputs
    'weekly', // weekly rule type inputs
    'monthly', // Monthly rule type inputs
    'cards' // Dropdown for card collections
  ];

  initialize () {
  }

  /**
   * Shows the name modal
   */
  showModal (event) {
    this.campaignTypeTarget.value = event.currentTarget.dataset.campaignType;

    new BSN.Modal(this.nameModalTarget).show()
  }

  /**
   * Called when the offer id input radiobox changed
   * @param event
   */
  offerInputChanged (event) {
  }

  /**
   * Called when the offer media box has been clicked (new offer selected)
   * @param event
   */
  mediaBoxClicked (event) {
    let inputField = event.currentTarget.querySelector('input[name="campaign[offer_id]"]')
    inputField.checked = true;

    for (let mediaBox of this.mediaBoxTargets) {
      mediaBox.classList.remove('active')
    }
    event.currentTarget.classList.add('active');

    // Set the max amount for amount selection on reminder campaign
    // let event_type = $('#campaign_campaign_actions_attributes_0_campaign_action_type_attributes_event_type');
    //
    // if (event_type.length > 0 && event_type.val() === 'reminder') {
    //   let threshold = $(this).data('threshold')
    //
    //   let amount_input = $('#campaign_campaign_actions_attributes_0_amount')
    //   amount_input.attr('max', threshold)
    //
    //   if (amount_input.val() > threshold) {
    //     amount_input.val(threshold);
    //   }
    // }
  }

  /**
   * Gets called when the rule type changes
   * @param event
   */
  ruleTypeChanged (event) {
    switch (event.currentTarget.value) {
      case 'IceCube::DailyRule': {
        this.dailyTarget.classList.remove('d-none');
        this.weeklyTarget.classList.add('d-none');
        this.monthlyTarget.classList.add('d-none');
        break;
      }
      case 'IceCube::WeeklyRule': {
        this.dailyTarget.classList.add('d-none');
        this.weeklyTarget.classList.remove('d-none');
        this.monthlyTarget.classList.add('d-none');
        break;
      }
      case 'IceCube::MonthlyRule': {
        this.dailyTarget.classList.add('d-none');
        this.weeklyTarget.classList.add('d-none');
        this.monthlyTarget.classList.remove('d-none');
        break;
      }
      default: {
        this.dailyTarget.classList.add('d-none');
        this.weeklyTarget.classList.add('d-none');
        this.monthlyTarget.classList.add('d-none');
      }
    }
  }
}
