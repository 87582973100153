import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["form"]

  initialize() { }

  submitForm(event) {

  }

  /**
   * Submit button clicked
   * @param event
   */
  submit(event) {
    this.formTarget.action = event.currentTarget.dataset.url;
  }
}
