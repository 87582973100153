import { Controller } from "stimulus"
import BSN from 'bootstrap.native/dist/bootstrap-native-v4'

/**
 * This controller starts Bootstrap popover functionality.
 *
 * Just assign this controller with target "button" to any button you want to start a popover
 */
export default class extends Controller {
  static targets = ["button"];

  connect () {
    for (let button of this.buttonTargets) {
      new BSN.Popover(button, {
        trigger: this.data.get('trigger'),
        placement: this.data.get('placement'),
        dismissible: this.data.get('dismissible')
      })
    }
  }
}
