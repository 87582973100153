import { Controller } from "stimulus"

/**
 * jasny bootstrap like file preview functionality
 *
 *
 */
export default class extends Controller {
  static targets = ["input", // The input of type file
    "preview", // An image tag which shows the preview
    "wrapper", // The wrapper with the fileinput and fileinput-new (or -exists) classes
    "remove", // [Hidden] input with value 0 or 1 to tell the backend the image should be removed
  ];

  /**
   * Shows a preview of the uploaded image
   *
   * data-action="file-preview#showPreview
   */
  showPreview () {
    const reader = new FileReader()
    const previewImage = this.previewTarget
    const previewWrapper = this.wrapperTarget
    this.removeTarget.value = 0

    reader.onload = function (event) {
      previewImage.src = event.target.result
      previewWrapper.classList.remove('fileinput-new')
      previewWrapper.classList.add('fileinput-exists')
    }

    reader.readAsDataURL(this.inputTarget.files[0])
  }

  /**
   * Removes image preview
   *
   * data-action="file-preview#removePreview
   */
  removePreview () {
    this.wrapperTarget.classList.remove('fileinput-exists')
    this.wrapperTarget.classList.add('fileinput-new')
    this.previewTarget.src = ""
    this.inputTarget.value = ""
    this.removeTarget.value = 1
  }
}
