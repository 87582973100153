import { Controller } from "stimulus"

/**
 * Controller for form action
 *
 * To apply bootstrap custom validation add `data-controller="form" data-target="form.form"` to the form.
 */
export default class extends Controller {
  static targets = ['form', 'password', 'passwordConfirmation'];

  connect () {
    const form = this.formTarget

    form.addEventListener("submit", function (event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add("was-validated");
    }, false);
  }

  /**
   * Updates the passwordConfirmationTarget pattern with the value of passwordTarget.
   * Obviously requires passwordConfirmationTarget and passwordTarget.
   * @param event
   */
  updateConfirmationPattern (event) {
    this.passwordConfirmationTarget.pattern = event.currentTarget.value
  }
}
