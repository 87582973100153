import {Controller} from "stimulus"
import moment from '../momentjs'
import d3 from 'd3';
import CalHeatMap from 'cal-heatmap';

/**
 * This controller starts cal-heatmap on the given target "calendar".
 *
 * If you want to use previous and next functionality just assign previous and next target to a button
 */
export default class extends Controller {
  static targets = ["calendar", "previous", "next"];

  /**
   *
   */
  initialize() {
    this.buildChart()
  }

  buildChart() {
    let self = this;
    let cal_range;
    this.cal = new CalHeatMap;
    let start_date = moment().tz('Europe/Berlin');

    if (window.matchMedia('(max-width: 480px)').matches) {
      cal_range = 1;
    } else if (window.matchMedia('(max-width: 768px)').matches) {
      cal_range = 2;
      start_date = start_date.subtract(1, 'months');
    } else {
      cal_range = 3;
      start_date = start_date.subtract(2, 'months');
    }

    this.cal.init({
      itemSelector: this.calendarTarget,
      domain: 'month',
      subDomain: 'x_day',
      range: cal_range,
      start: start_date.toDate(),
      domainGutter: 10,
      cellSize: 40,
      cellRadius: 1,
      tooltip: true,
      highlight: ["now"],
      minDate: moment('20120501').tz('Europe/Berlin').toDate(), //june 2012. First checkin event
      maxDate: moment().tz('Europe/Berlin').toDate(), //today. obviously...
      considerMissingDataAsZero: true,
      displayLegend: false,
      itemName: ['Fraud-Event'], //TODO
      previousSelector: this.previousTarget,
      nextSelector: this.nextTarget,
      animationDuration: 250,
      data: `${this.calendarTarget.dataset.url}.json?start_date={{t:start}}&end_date={{t:end}}`,
      afterLoadData(data) {
        const stats = [];
        for (let key in data) {
          const value = data[key];
          stats[Date.parse(key) / 1000] = value;
        }
        return stats;
      },
      label: {
        position: 'top'
      },
      domainMargin: [0, 20, 0, 20],
      legend: [0, 5, 9, 15],
      onClick(date, value) {
        fetch(self.calendarTarget.dataset.url + '?date_clicked=' + (date.getTime() / 1000), {
          headers: {
            'Accept': 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
            'X-Requested-With': 'XMLHttpRequest'
          },
          method: 'GET',
          credentials: 'include',
          cache: 'no-store'
        }).then(function(data) {
          return data.text();
        }).then(function(response) {
          let script = document.createElement('script');
          script.nonce = document.querySelector('meta[name=csp-nonce]').content;
          script.setAttribute('nonce', script.nonce);
          script.text = response;
          document.head.appendChild(script).parentNode.removeChild(script)
        });

        return true
      },
      subDomainDateFormat(date) {
        return moment(date).format('dddd, D. MMMM YYYY');
      },
      subDomainTitleFormat: {
        empty: '{date}',
        filled: '{count} {name} am {date}'
      },
      subDomainTextFormat: '%d',
      domainLabelFormat(date) {
        return moment(date).format('MMM YYYY');
      },
      legendTitleFormat: {
        lower: 'weniger als {min} {name}',
        inner: 'zwischen {down} und {up} {name}',
        upper: 'mehr als {max} {name}'
      }
    });
  }

  showToday(event) {
    event.preventDefault();
    this.cal.jumpTo(new Date, true);
  }
}
