import { Controller } from "stimulus"
// import Choices from 'choices.js'
import Selectr from 'mobius1-selectr/src/selectr'

/**
 * This controller starts choices functionality.
 *
 * Just assign this controller with target "select" to any select you want to render as choices select
 */
export default class extends Controller {
  static targets = ['select', 'selectTag'];

  initialize () {
    this.initSelectr()
  }

  initSelectr () {
    this.selector = new Selectr(this.selectTarget, {
      taggable: this.selectTarget.multiple,
      clearable: this.selectTarget.multiple,
      sortSelected: this.selectTarget.multiple ? false : 'text'
    });
    this.selector.on('selectr.init', function () {
      if (this.el.multiple) {
        // Add select all/none buttons
        let noneLink = document.createElement('a');
        noneLink.href = '#';
        noneLink.classList.add('btn', 'btn-primary', 'btn-sm', 'ml-2', 'my-2');
        noneLink.innerHTML = 'Keine';
        noneLink.dataset.action = 'choices#selectNone';
        this.container.getElementsByClassName('selectr-options-container')[0].prepend(noneLink);

        let allLink = document.createElement('a');
        allLink.href = '#';
        allLink.classList.add('btn', 'btn-primary', 'btn-sm', 'ml-2', 'my-2');
        allLink.innerHTML = 'Alle';
        allLink.dataset.action = 'choices#selectAll';
        this.container.getElementsByClassName('selectr-options-container')[0].prepend(allLink);
      }
    })

    if (this.selectTarget.dataset.dataUrl) {
      let self = this
      this.selector.on('selectr.search', function (query) {
        self.fetchRemoteData(query)
      })
    }
  }

  fetchRemoteData (query) {
    let self = this
    fetch(`${this.selectTarget.dataset.dataUrl}&search_card=${query}`, {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
        'X-Requested-With': 'XMLHttpRequest'
      },
      method: 'GET',
      credentials: 'include',
      cache: 'no-store'
    }).then(function (data) { // Promise
      return data.text();
    }).then(function (response) {
      response = JSON.parse(response)

      for (let o of response) {
        delete Object.assign(o, { value: o.id.toString() }).id
        delete Object.assign(o, { text: o.number_code }).number_code
      }

      self.selector.add(response, true)
    });
  }

  selectAll (event) {
    event.preventDefault();
    this.selector.setValue(Array.from(this.selectTarget.options).map(c => c.value))
  }

  selectNone (event) {
    event.preventDefault();
    this.selector.clear();
  }

  submitForm (event) {
    event.currentTarget.form.submit();
  }
}
