import { Controller } from "stimulus"
import moment from '../momentjs'
import Highcharts, { highchartsDe } from '../highcharts'
import Rails from '@rails/ujs'
import { truncateString } from '../utils'

export default class extends Controller {
  static targets = ["form", "category", "scope", "startDate", "endDate", "chartArea", "title", "categoryButton", "scopeButton", "scopeTitle", "scopeStart", "scopeEnd"];

  initialize () {
    //TODO maybe save data attributes on disconnect()
    this.initChart()
    this.dashScope = this.scopeButtonTargets.find(button => button.classList.contains('active')).dataset.scope;
    let scope = { 'hour': 'day', 'day': 'week', 'month': 'month' }[this.dashScope];
    this.startDate = moment(this.startDateTarget.value).tz('Europe/Berlin').clone().startOf(scope).format()
    this.endDate = moment(this.startDateTarget.value).tz('Europe/Berlin').clone().endOf(scope).format()
  }

  // Form Action
  showLoading () {
    this.chart.showLoading();
  }

  // Response event for ajax:complete on form
  updateData (event) {
    let data = JSON.parse(event.detail[0]);

    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }

    data.map((value) => this.chart.addSeries(value, false));

    this.chart.hideLoading();

    this.chart.hideNoData();

    this.chart.series.length === 0 ? this.chart.showNoData(highchartsDe.noData) : this.chart.showNoData();

    this.chart.redraw();
  }

  // Category button action
  changeCategory (event) {
    event.preventDefault();

    this.categoryButtonTargets.forEach((element, index) => {
      element.classList.toggle('active', element === event.currentTarget)
    });

    this.category = event.currentTarget.dataset.category;
    this.titleTarget.innerText = event.currentTarget.text;

    this.submitForm()
  }

  // Scope button action
  changeScope (event) {
    event.preventDefault();

    this.scopeButtonTargets.forEach((element, index) => {
      element.classList.toggle('active', element === event.currentTarget)
    });

    this.dashScope = event.currentTarget.dataset.scope;

    this.updateDates();
    this.updateScopeText();
    this.updateGraphXKeys();
    this.submitForm();
  }

  // Previous date button action
  previous (event) {
    event.preventDefault();

    let format = {
      'hour': 'd',
      'day': 'w',
      'month': 'M'
    }[this.dashScope];

    this.startDate = moment(this.startDate).tz("Europe/Berlin").subtract(1, format).format();
    this.endDate = moment(this.endDate).tz("Europe/Berlin").subtract(1, format).format();

    this.updateScopeText();
    this.updateGraphXKeys();
    this.submitForm();
  }

  // Next date button action
  next (event) {
    event.preventDefault();

    let format = {
      'hour': 'd',
      'day': 'w',
      'month': 'M'
    }[this.dashScope];

    this.startDate = moment(this.startDate).tz("Europe/Berlin").add(1, format).format();
    this.endDate = moment(this.endDate).tz("Europe/Berlin").add(1, format).format();

    this.updateScopeText();
    this.updateGraphXKeys();
    this.submitForm();
  }

  updateDates () {
    let scope = { 'hour': 'day', 'day': 'week', 'month': 'month' }[this.dashScope];

    let startDate = moment(this.startDateTarget.value).tz('Europe/Berlin').clone().startOf(scope);
    let endDate = moment(this.startDateTarget.value).tz('Europe/Berlin').clone().endOf(scope);

    if (scope === 'month') {
      startDate = startDate.subtract(6, 'M'); // Show 6 months
    }

    this.startDate = startDate.format();
    this.endDate = endDate.format();
  }

  updateScopeText () {
    let startValueFormat, endValueFormat;

    switch (this.dashScope) {
      case 'hour':
        startValueFormat = 'llll';
        endValueFormat = 'LT';
        break;
      case 'day':
        startValueFormat = endValueFormat = 'L';
        break;
      case 'month':
        startValueFormat = endValueFormat = 'll';
        break;
    }

    let startValue = moment(this.startDate).clone().format(startValueFormat);
    let endValue = moment(this.endDate).clone().format(endValueFormat);

    this.scopeTitleTarget.innerText = this.dashScopeText;
    this.scopeStartTarget.innerText = startValue;
    this.scopeEndTarget.innerText = endValue;
  }

  updateGraphXKeys () {
    this.chart.xAxis[0].setCategories(this.buildXAxis(), false);
  }

  initChart () {
    let self = this;

    new Highcharts.Chart({
      chart: {
        renderTo: this.chartAreaTarget.id,
        type: 'column',
        styledMode: true,
        events: {
          load (event) {
            if (this.series.length === 0) {
              this.hideNoData();
              this.showNoData(highchartsDe.noData);
            } else {
              this.hideNoData();
              this.showNoData();
            }
            self.submitForm()
            // Rails.fire(self.formTarget, 'submit');
          }
        }
      },
      exporting: {
        enabled: false
      },
      title: {
        text: undefined
      },
      legend: {
        enabled: true,
        labelFormatter () {
          if (this.yData.length <= 0) {
            return this.name;
          }
          let total = 0;
          for (let val of this.yData) {
            total += val;
          }
          if (this.linkedSeries.lenght > 0) {
            for (val of this.linkedSeries[0].yData) {
              total += val
            }
          }
          return `<span>${truncateString(this.userOptions.stack, 40)}</span><span class="ml-2">${total}</span>`;
        },
        floating: false,
        align: 'center',
        verticalAlign: 'bottom',
        useHTML: true,
        itemMarginBottom: 11,
        itemWidth: 350
      },
      xAxis: {
        categories: this.buildXAxis()
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: false
        }
      },
      tooltip: {
        formatter () {
          if (this.series.name === 'Coupon') {
            return `<b>${this.series.userOptions.stack}</b><br/>${this.series.name}: <b>${this.y}</b>`;
          } else {
            return `<b>${this.series.userOptions.stack}</b><br/>${this.series.name}: <b>${this.y}</b><br/>Total: <b>${this.point.stackTotal}</b>`;
          }
        },
        followPointer: true
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          events: {
            legendItemClick (event) {
              let salespoint_ids = event.target.chart.series.filter(x => x.visible).map(x => x.userOptions.id);

              if (this.visible) {
                salespoint_ids = salespoint_ids.filter(x => x !== this.userOptions.id);
              } else {
                salespoint_ids.push(this.userOptions.id);
              }

              fetch(self.chartAreaTarget.dataset.url, {
                body: JSON.stringify({
                  user: {
                    salespoint_ids: salespoint_ids,
                  }
                }),
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
                },
                method: 'PATCH',
                credentials: 'include'
              });

              return true;
            }
          }
        }
      },
      lang: {
        noData: 'Keine Filialen ausgewählt'
      },
      series: [
        {
          data: []
        }
      ]
    });
  }

  buildXAxis () {
    let xKeys = [];
    let format = '';

    switch (this.dashScope) {
      case 'hour':
        format = 'LT';
        break;
      case 'day':
        format = 'ddd L';
        break;
      case 'month':
        format = 'MMM YY';
        break;
    }

    let range = moment.range(this.startDateTarget.value, this.endDateTarget.value);

    for (let month of range.by(this.dashScope)) {
      xKeys.push(month.format(format))
    }

    return xKeys
  }

  submitForm () {
    // this.formTarget.submit()
    Rails.fire(this.formTarget, 'submit');
  }

  get chart () {
    return Highcharts.charts[this.chartAreaTarget.dataset.highchartsChart]
  }

  /// SCOPE
  // We have to use custom name here, because scope is a reserverd keyword in stimulus
  get dashScope () {
    return this.data.get('dashScope');
  }

  set dashScope (value) {
    this.data.set('dashScope', value);
    this.dashScopeText = this.scopeButtonTargets.find(x => x.id === value).dataset.text;
    this.scopeTarget.value = value;
  }

  /// SCOPE TEXT

  get dashScopeText () {
    return this.data.get('dashScopeText');
  }

  set dashScopeText (value) {
    this.data.set('dashScopeText', value);
  }

  /// START DATE

  get startDate () {
    return this.data.get('startDate');
  }

  set startDate (value) {
    this.data.set('startDate', value);
    this.startDateTarget.value = value;
  }

  /// END DATE

  get endDate () {
    return this.data.get('endDate');
  }

  set endDate (value) {
    this.data.set('endDate', value);
    this.endDateTarget.value = value;
  }

  /// CATEGORY

  get category () {
    return this.data.get('category');
  }

  set category (value) {
    this.data.set('category');
    this.categoryTarget.value = value
  }
}
