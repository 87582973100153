import { Controller } from "stimulus"
import BSN from 'bootstrap.native/dist/bootstrap-native-v4'

/**
 * This controller starts Bootstrap dropdown functionality.
 */
export default class extends Controller {
  static targets = ["element"];

  connect () {
    for (let element of this.elementTargets) {
      new BSN.Dropdown(element)
    }
  }
}
