import Highcharts from 'highcharts/highcharts'
import addNoData from "highcharts/modules/no-data-to-display"
addNoData(Highcharts);

export const highchartsDe = {
  loading: 'Lade Daten...',
  months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
    'August', 'September', 'Oktober', 'November', 'Dezember'],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  decimalPoint: ',',
  numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'], // SI prefixes used in axis labels
  resetZoom: 'Zoom zurücksetzen',
  resetZoomTitle: 'Zoom zurücksetzen 1:1',
  thousandsSep: '.',
  noData: 'Keine Daten vorhanden'
};
export const highchartsEn = {
  loading: 'Loading...',
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  decimalPoint: '.',
  numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'], // SI prefixes used in axis labels
  resetZoom: 'Reset zoom',
  resetZoomTitle: 'Reset zoom level 1:1',
  thousandsSep: ' ',
  noData: 'No data to display'
};

Highcharts.setOptions({lang: document.documentElement.lang === 'de' ? highchartsDe : highchartsEn});

export { Highcharts as default }
