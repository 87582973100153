import {Controller} from "stimulus"
import BSN from 'bootstrap.native/dist/bootstrap-native-v4'

/**
 * This controller starts Bootstrap tooltip functionality.
 *
 * Just assign this controller with target "button" to any button you want to start a tooltip
 */
export default class extends Controller {
  static targets = ["button"];

  initialize() {
    for ( let button of this.buttonTargets) {
      new BSN.Tooltip(button)
    }
  }
}
