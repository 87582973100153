import { Controller } from "stimulus"
import moment from '../momentjs'
import Pikaday from "pikaday-time"
import { uncapitalize } from "../utils";

/**
 * This controller starts a the datepicker. You can either use startDate or endDate targets or both.
 * If you use both. They will be linked
 */
export default class extends Controller {
  static targets = ["startDate", "endDate"];

  initialize () {
    try {
      this.setupStartDatePicker();
      this.setupEndDatePicker();

      this.startDate = this.startPicker.getDate();
      this.endDate = this.endPicker.getDate();
    } catch (err) {
    }
  }

  /**
   * Setup datepicker on given target
   * @param target DOMElement you want the picker assigned to
   * @param onSelect Function if you select a date
   */
  setupPicker (target, onSelect = undefined) {
    let filteredDatasets = this._parseDataset(target, onSelect);

    return new Pikaday(filteredDatasets)
  }

  /**
   * Start picker
   */
  setupStartDatePicker () {
    let self = this;

    // this.startPicker = this.setupPicker(this.startDateTarget, function() { self.startDate = this.getDate()})
    this.startPicker = this.setupPicker(this.startDateTarget, function () {
      if (this.config().field.dataset.dateViewMode === 'months') {
        // TODO maybe we can config the viewMode to use year also
        self.startDate = this.getMoment().startOf('month').toDate()
      } else {
        self.startDate = this.getDate()
      }
    })
  }

  /**
   * End Picker
   */
  setupEndDatePicker () {
    let self = this;

    // this.endPicker = this.setupPicker(this.endDateTarget, function() { self.endDate = this.getDate()})
    this.endPicker = this.setupPicker(this.endDateTarget, function () {
      if (this.config().field.dataset.dateViewMode === 'months') {
        // TODO maybe we can config the viewMode to use year also
        self.endDate = this.getMoment().endOf('month').toDate()
      } else {
        self.endDate = this.getDate()
      }
    })
  }

  /**
   * Parses the dataset attributes of the given target.
   * @param target DOMElement you want the picker assigned to
   * @param onSelect Function if you select a date
   * @returns {{field: *, defaultDate: Date, setDefaultDate: boolean, showTime: boolean, firstDay: number, formatStrict: boolean, i18n: {previousMonth: string, nextMonth: string, months: string[], weekdays: string[], weekdaysShort: string[]}, onSelect: *}}
   * @private
   */
  _parseDataset (target, onSelect) {
    let self = this;
    let datasets = Object.assign({}, target.dataset);

    return Object.keys(datasets)
      .filter(key => key.startsWith('date'))
      .reduce((obj, key) => {
        let newKey = uncapitalize(key.replace(/^(date)/, '')); // key without date prefix
        obj[newKey] = datasets[key];

        if (moment(obj[newKey], moment.ISO_8601, true).isValid()) {
          obj[newKey] = moment(obj[newKey], moment.ISO_8601, true).toDate();
        }

        return obj;
      }, {
        field: target,
        defaultDate: new Date(),
        setDefaultDate: true,
        showTime: false,
        firstDay: 1,
        formatStrict: true,
        i18n: {
          previousMonth: 'Vormonat',
          nextMonth: 'Nächster Monat',
          months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
          weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
          weekdaysShort: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
        },
        onSelect: onSelect,
        use24hour: true
      });
  }

  set startDate (value) {
    this.data.set('startDate', value);

    if (this.startPicker) {
      this.startPicker.setStartRange(value);
    }

    if (this.endPicker) {
      this.endPicker.setStartRange(value);
      this.endPicker.setMinDate(value);
    }
  }

  set endDate (value) {
    this.data.set('endDate', value);

    if (this.startPicker) {
      this.startPicker.setEndRange(value);
      this.startPicker.setMaxDate(value);
    }

    if (this.endPicker) {
      this.endPicker.setEndRange(value);
    }
  }
}
